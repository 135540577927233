import React from 'react'

export default function ProjectCard({ name, src, alt, category, description, stack, liveDemoUrl, gitHubUrl, key }) {
    return (
        <div className="project-card" key={key}>
            <div className='img-container'>
                <img src={src} alt={alt} />
                <h5>{category}</h5>
            </div>
            <h3>{name}</h3>
            <p>{stack}</p>
            <div className='button-container'>
                <a href={liveDemoUrl} target='_blank' rel="noopener noreferrer" className="demo-btn btn">Live Demo</a>
                <a href={gitHubUrl} target='_blank' rel="noopener noreferrer" className="github-btn btn">GitHub</a>
            </div>
        </div>
    )
}
