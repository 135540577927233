import { AiOutlineHtml5 as HtmlIcon } from "react-icons/ai";
import { BiLogoCss3 as CssIcon } from "react-icons/bi";
import { TbBrandJavascript as JsIcon } from "react-icons/tb";
import { FaReact as ReactIcon } from "react-icons/fa";
import { FaNodeJs as NodeJsIcon } from "react-icons/fa";
import { AiOutlinePython as PythonIcon } from "react-icons/ai";
import { SiMongodb as MongoDbIcon } from "react-icons/si";
import { SiPostgresql as PostgresqIcon } from "react-icons/si";
import { PiFileSqlThin as SqlIcon } from "react-icons/pi";
import { FaPhp as PhpIcon } from "react-icons/fa6";
import { FaGitSquare as GitIcon } from "react-icons/fa";
import { FaGithub as GithubIcon } from "react-icons/fa"


export const skills = [
    {
        name: "HTML",
        icon: <HtmlIcon className="icon" />,
    },
    {
        name: "CSS",
        icon: <CssIcon className="icon" />,
    },
    {
        name: "JavaScript",
        icon: <JsIcon className="icon" />,
    },
    {
        name: "React",
        icon: <ReactIcon className="icon" />,
    },
    {
        name: "NodeJS",
        icon: <NodeJsIcon className="icon" />,
    },
    {
        name: "Python",
        icon: <PythonIcon className="icon" />,
    },
    {
        name: "MongoDB",
        icon: <MongoDbIcon className="icon" />,
    },
    {
        name: "Postgresq",
        icon: <PostgresqIcon className="icon" />,
    },
    {
        name: "SQL",
        icon: <SqlIcon className="icon" />,
    },
    {
        name: "PHP",
        icon: <PhpIcon className="icon" />,
    },
    {
        name: "Git",
        icon: <GitIcon className="icon" />,
    },
    {
        name: "Github",
        icon: <GithubIcon className="icon" />,
    },
];