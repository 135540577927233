export const reviews = [
    {
        isActive: true,
        name: "Nicky",
        comment:
            <>
                Karoly is absolutely incredible at what he does!
                I needed my entire business website redone as I had tried to
                do my own and he completely transformed my page into a beautiful
                sleek and modern page which I am now proud to share. I was so
                indecisive and changed my mind a few times and no task was too big
                and he always had great ideas to help improve my original vision.
                Thank you to Karoly for turning my vision into a reality!
            </>,
        company: 'Sparkling Willow Photography'
    },
    {
        isActive: true,
        name: "Daniel",
        comment:
            <>
                Karoly took my old, tired website and totally transformed
                it into a contemporary, vibrant place that people can go to and be
                introduced to my business. I found him to be helpful and he
                assisted me everywhere he could to get the most creative and
                effective outcome we could. It was a bespoke service that he provided
                which allowed us to really tailor it to what I wanted. I am very
                happy with the end result and look forward to working with him
                again. I couldn't recommend the service highly enough.
            </>,
        company: 'DMP Plumbing & Heating'
    },
    {
        isActive: true,
        name: "Mary",
        comment:
            <>
                Creating a completely new website with Karoly was an outstanding
                experience from start to finish. As someone starting from scratch,
                I was unsure of where to begin, but Karoly guided me through every
                step of the process with expertise and patience. He took the time
                to understand my vision and business needs, translating them into a
                stunning, user-friendly website that exceeded my expectations. Karoly's
                dedication to providing a bespoke service was evident throughout, and
                I am beyond thrilled with the result. I highly recommend Karoly to anyone
                looking to build a new website from the ground up.
            </>,
        company: "Mary's Sewing Workshop"
    },
    {
        isActive: true,
        name: "Anett",
        comment:
            <>
                Karoly is truly an excellent professional.
                Dynamic, energetic, creative, attentive, and precise.
                The web interface he created, Talpak, is in the best hands. My guests praise his work.
                They love looking at my website.
                I believe that if you desire an eye-catching, attractive, and captivating interface,
                don't hesitate to contact him, because he will bring the website of your dreams to life.
            </>,
        company: "Talpak Massage Salon"
    },
];
